@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Outfit";
}

.animate-bounce {
  animation: bounce 1s;
}

.hg-button {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  font-size: 2em !important;
}

.shree-ganesh .hg-button-enter {
  width: 1em !important;
}

.shree-ganesh .hg-button-space {
  flex-grow: 4 !important;
}

.shree-ganesh .hg-row:nth-child(2) {
  padding-left: 2.25em;
  padding-right: 2.25em;
}

.shree-ganesh .hg-row:nth-child(3) {
  padding-left: 4.5em;
}

.shree-ganesh .hg-row:nth-child(4) {
  padding-left: 9em;
}

.layout-number .hg-row:nth-child(4) {
  padding-left: 17em;
}

.shree-ganesh .hg-button-bksp {
  margin-left: 0.25em;
}

.simple-keyboard {
  padding: 1em !important;
  bottom: 0;
  position: fixed;
}

.imageWrapper {
  position: relative;
}
.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
}